@use "../../../shared/styles/variables" as v;
@use "../../../shared/styles/mixins" as m;

.switcher-between-pages {
  display: inline-flex;
  justify-content: center;
  margin-right: 20px;
  padding: 6px 8px;
  // font-size: 12px;
  background-color: v.$light-grey-color;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;

  &-mobile {
    margin-right: 0;
  padding: 4px 7px;
  font-size: 10px;
  }

  &__button,
  &__link {
    display: block;
    min-width: 75px;
    padding: 10px 24px;
    color: v.$main-dark-color;
    background-color: v.$main-light-color;
    @include m.flex( $direction: row, $justify: center, $align: center, $wrap: nowrap);

    &--current,
    &__link--current {
      color: v.$light-grey-color;
      background-color: v.$main-dark-color;
    }
  }

  &__link {
    text-decoration: none;
    text-align: center;
  }

  &__text {
    @include m.fonts-basis(12px, $weight: 500);
  }
}

.switcher-between-pages__button,
.switcher-between-pages__link {
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

// .link-switch-users {
//   border-top-left-radius: 20px;
//   border-bottom-left-radius: 20px;
// }

// .link-switch-equip {
//   border-top-right-radius: 20px;
//   border-bottom-right-radius: 20px;
// }
