.template-task {

  &__button {
    width: 100%;
  }

  &__info-list {
    display: flex;
    justify-content: start;
  }

  &__info-item {
    width: 15%;
    max-width: 250px;
    padding: 10px;
  }

}
