:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
}

.direction-section {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1rem;
  height: 240px;
  background-color: var(--main-light-color);
  border-radius: 28px;
}

.direction-section-wrapper {
  box-sizing: border-box;
  min-width: 450px;
  /* width: 450px; */
  height: 100%;
  padding: 1rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.direction-unit-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.direction-section-search-wrapper {
  position: relative;
  display: inline-block;
}

.direction-section-search-wrapper::after {
  position: absolute;
  content: "";
  top: 0px;
  right: 0px;
  width: 42px;
  height: 31px;
  z-index: 10;
  border-radius: 20px;
  background: var(--main-dark-color) url('../../Images/loupe_white.svg') no-repeat center;
}


.direction-unit-wrapper::-webkit-scrollbar {
  width: 13px;
  background: #d9d9d9;
  border-radius: 10px;
}

.direction-unit-wrapper::-webkit-scrollbar-thumb {
  background: var(--main-dark-color);
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.direction-section-heading {
  position: absolute;
  right: 71.5%;
  top: 100px;
  width: 190px;
  padding: 0.6rem 1.5rem;
  margin: 0;
  transform: rotate(-90deg);

  border-radius: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background-color: var(--main-dark-color);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
}

.direction-section-search-field {
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 0.5rem;
  height: 31px;
  width: 330px;
  border-radius: 20px;
  color: var(--main-dark-color);
  border: 0px;
}

.direction-section-search-field:focused {
  border: 2px solid var(--main-dark-color);
}

.direction-button {
  box-sizing: border-box;
  background-color: var(--main-dark-color);
  color: #ffffff;
  padding: 10px;
  border-radius: 20px;
  font-weight: 500;
}
