@use './variables' as v;
@use './mixins' as m;

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  font-size: v.$font-size;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

h1 {
  @include m.fonts-basis($weight: 400, $size: 28px, $line-height: 1.17, $color: v.$white-color);
}

input {
  font-family: "Roboto";
  font-size: v.$font-size;
  -webkit-text-size-adjust: 100%;

  &:focus-visible {
    @include m.outline($width: 2px, $offset: -2px, $color: $main-dark-color-shadow);
  }

  &::placeholder {
    @include m.fonts-basis($size: v.$font-size-medium, $weight: 500, $color: v.$placeholder-color);
  }
}

textarea {
  &:focus-visible {
    @include m.outline($width: 2px, $offset: -2px, $color: $main-dark-color-shadow);
  }
  
  &::placeholder {
    @include m.fonts-basis($size: v.$font-size-medium, $weight: 500, $color: v.$placeholder-color);
  }
}

button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: v.$main-dark-color;
  background-color: transparent;
  &:focus-visible {
    @include m.outline($width: 2px, $offset: -2px, $color: $main-dark-color-shadow);
  }
}

*::-webkit-scrollbar {
  width: 1.5rem;
  background: #d9d9d9;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: var(--main-dark-color);
  border-radius: 13px;
  border: 4px solid transparent;
  background-clip: padding-box;
}

.box-shadow {
  box-shadow: 0px 4px 4px 1px v.$main-dark-color-shadow;
}

/*errors*/

.loading-error-message {
  display: inline-block;
  text-align: center;
  margin: auto;
  align-self: center;
  @include m.fonts-basis($size: 25px, $color: v.$main-dark-color, $weight: 500);
}

//components

.submit-button {
  box-sizing: border-box;
  display: block;
  width: 60%;
  padding: 1rem 2rem;
  @include m.fonts-basis($size: 1.4rem, $weight: 500);
  text-align: center;
  margin: 0 auto;
  @include m.rounded-corners(30px);
  background-color: v.$main-light-color;
}

.button--box-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}