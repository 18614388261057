@use "../../../shared/styles/variables.scss" as v;
@use "../../../shared/styles/mixins.scss" as m;

.dropdown-list {
  position: relative;
  color: v.$main-dark-color;

  &__field {
    box-sizing: border-box;
    position: relative;
    // height: 35px;
    background-color: v.$main-light-color;
    @include m.fonts-basis($size: 11px, $weight: 600);
    @include m.rounded-corners(28px);
    @include m.flex($align: center);
    gap: 0.5rem;
    padding: 10px 15px;
    z-index: 20;
    box-shadow: 0px 4px 10px 1px v.$main-dark-color-shadow;
  }

  &__expand-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    @include m.square_obj(28px, 50%);
    @include m.background-image(
      $url: "../../../shared/assets/actions/arrow_down-icon.svg",
      $size: 90%
    );
    transition: all 0.7s;

    &:hover {
      background-color: v.$darker-grey-color;
    }
  }

  &__item-container {
    position: relative;
    @include m.flex($align: center);
  }

  &__list {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    border: 1px solid black;
    overflow: hidden;
    height: 285px;
    width: 100%;
    background-color: v.$main-light-color;
    z-index: 10;
    border-radius: 0px 0px 25px 25px;
    padding: 50px 15px 20px;
    clip-path: inset(0 0 100% 0); // Полностью скрываем блок
    transition: clip-path 1.5s ease-out;

    &-list {
      padding-right: 10px;
      max-height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 15px;
        background: #d9d9d9;
        border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: var(--main-dark-color);
        border-radius: 13px;
        border: 4px solid transparent;
        background-clip: padding-box;
      }
    }

    &_opened {
      clip-path: inset(0 0 0 0); // Показываем блок
    }

    &-container {
      position: relative;
      background-color: v.$white-color;
      @include m.rounded-corners(28px);
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    @include m.flex();
    background-color: v.$white-color;
    padding: 8px 20px;
    @include m.fonts-basis($size: 11px, $weight: 500);
    @include m.rounded-corners(15px);
    margin-bottom: 7px;

    // &:last-child {
    //   margin-bottom: 0rem;
    // }

    &-button {
      box-sizing: border-box;
      z-index: 1000;
      position: absolute;
      top: 0px;
      right: 2px;
      height: 90%;
      @include m.flex($justify: center, $align: center);
      // @include m.one-hundred();
    // padding: 0.8rem 2rem;

    }

    &:hover {
      background-color: v.$darker-grey-color;
    }
  }

  // &__delete-button {
  //   @include m.box(1.5)
  // }

  &__empty-message {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
  }

  &__expand-button.chosen {
    @include m.background-image(
      $url: "../../../shared/assets/actions/arrow_down-white-icon.svg",
      $size: 45%
    );
  }
}

.dropdown-list-inner-list {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 5px;

  &__item {
    @include m.border(3px, $color: v.$darker-grey-color);
  }
}

.chosen {
  background-color: v.$main-dark-color;
  color: v.$white-color;
}

.rotated {
  transform: rotate(180deg);
}

.black-and-white {

  .dropdown-list {
    
    &__field {
      background-color: v.$main-dark-color;
      @include m.fonts-basis($color: v.$white-color, $weight: 500, $size: 14px);
      padding: 7px 15px;
    }
  }
}