@use "../../../shared/styles/variables.scss" as vars;
@use "../../../shared/styles/mixins.scss" as mixins;

.districts {
  position: relative;
  color: vars.$main-dark-color;
  font-size: 14px;
}

.districts-field {
  position: relative;
  background-color: vars.$main-light-color;
  @include mixins.fonts-basis($weight: 600);
  @include mixins.rounded-corners(28px);
  @include mixins.flex($align: center);
  padding: 15px 10px;
  z-index: 100;
  font-size: 11px;

  box-shadow: 0px 4px 10px 1px vars.$main-dark-color-shadow;
}

.districts-list {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  border: 1px solid black;
  overflow: hidden;
  // @include mixins.flex(column, center, center);
  height: 285px;
  width: 100%;
  // height: 0;
  background-color: vars.$main-light-color;
  // transition: height 1.5s;
  z-index: 10;
  border-radius: 0px 0px 25px 25px;
  padding: 50px 15px 20px;
  clip-path: inset(0 0 100% 0); // Полностью скрываем блок
  transition: clip-path 1.5s ease-out;
  
  &__dirs-list {
    // background-color: vars.$main-light-color;
    padding-right: 10px;
    max-height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 12px;
      background: var(--light-grey);
      border-radius: 10px;
      border: 1px solid var(--darker-grey);
    }
  
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: var(--main-dark-color);
      border-radius: 15px;
      border: 3.5px solid transparent;
      background-clip: padding-box;
    }
  }
  
  &__container {
    position: relative;
    background-color: vars.$white-color;
    @include mixins.rounded-corners(28px);
  }

  &__item {
    position: relative;
    z-index: 1;
    @include mixins.flex();
    background-color: vars.$white-color;
    padding: 8px 20px;
    @include mixins.fonts-basis($weight: 500);
    @include mixins.rounded-corners(15px);

    &:not(:last-child) {
      margin-bottom: 7px;
    }

    &:hover {
      background-color: vars.$darker-grey-color;
    }
  }

  &__expand-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    @include mixins.square_obj(3.5rem, 50%);
    @include mixins.background-image($url: "../../../shared/assets/actions/arrow_down-icon.svg", $size: 90%);
    transition: all 0.7s;

    &:hover {
      background-color: vars.$darker-grey-color;
    }
  }
     
  &_opened {
    clip-path: inset(0 0 0 0); // Показываем блок
  }
}

.places-list {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 12px;

  &__item {
    @include mixins.border(3px, $color: vars.$darker-grey-color);
  }
}

.chosen {
  background-color: vars.$main-dark-color;
  color: vars.$white-color;
}

.rotated {
  transform: rotate(180deg);
}

.districts-list__expand-button.chosen {
  @include mixins.background-image($url: "../../../shared/assets/actions/arrow_down-white-icon.svg", $size: 45%);
}