.role-suggestions {
  position: absolute;
  top: 135px;
  z-index: 15;
  min-width: 200px;
  padding: 10px;
  list-style-type: none;
  background-color: #ffffff;
  border: 2px solid #211b27;
  border-radius: 20px;
}

.role-suggestions li {
  padding: 10px 0;
}

.role-suggestions li:hover {
  background-color: #ebebeb;
}
