.template-tasks {
  position: absolute;
  z-index: 10;
  background-color: rgba(102, 101, 100, 0.7);
  backdrop-filter: blur(1px);

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__modal {
    width: 50%;
    max-width: 1050px;
    height: 80%;
    margin: auto;
    background-color:  #ffffff;
    border-radius: 25px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: $main-dark-color;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  &__title {
    margin-left: auto;
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;
  }

  &__button--close {
    margin-left: auto;
  }

  &__list {
    margin-top: 30px;
    margin-right: 35px;
    margin-bottom: 30px;
    max-height: calc(100% - 30vh);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__item {
    position: relative;
  }

  &__item button:hover,
  &__item button:active ,
  &__item button:focus{
    background-color: #bebebe52;
  }

  &__item:first-child button::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #BEBEBE;
  }

  &__item button::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #BEBEBE;
  }

  &__item button:hover::after,
  &__item button:active:after,
  &__item button:focus::after{
    background-color: $main-dark-color;
  }

  &__button--save {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: 45px;
    min-width: 200px;
  }
}
