:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --liht-grey: #eeeeee;
  --darker-grey: #D4D2D6;
}

.switcher-wrapper {
  display: flex;
  margin-right: 20px;
  padding: 6px 10px;
  font-size: 12px;
  background-color: var(--liht-grey);
  border-radius: 10px;
}

.switcher-wrapper-mobile {
  margin-right: 0;
  padding: 4px 7px;
  font-size: 10px;
}

.link-switch {
  display: block;
  padding: 10px 24px;
  color: #000000;
  background-color: var(--main-light-color);
  text-decoration: none;
}

.switcher-wrapper-mobile .link-switch {
  padding: 6px 16px;
}

.link-switch-current {
  color: #eeeeee;
  background-color: var(--main-dark-color);
}

.link-switch-users {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.link-switch-equip {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

