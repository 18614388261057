:root {
    --main-dark-color: #211b27;
    --main-light-color: #F0D07E;
}

.area-section {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 1rem;
    height: 240px;
    background-color: var(--main-light-color);
    border-radius: 28px;
}

.area-section-wrapper {
    box-sizing: border-box;
    min-width: 450px;
    /* width: 450px; */
    height: 100%;
    padding: 1rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.area-unit-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.area-section-search-wrapper {
    position: relative;
    display: inline-block;
}

.area-section-search-wrapper::after {
    position: absolute;
    content: "";
    top: 0px;
    right: 0px;
    width: 42px;
    height: 31px;
    z-index: 10;
    border-radius: 20px;
    background: var(--main-dark-color) url('../../Images/loupe_white.svg') no-repeat center;
}

.unit-list {
    margin: 0;
    padding:0;
    margin: 0 0.8rem 0 2.4rem;
    list-style: none;
    transition: max-width 1.5s ease;
}

.unit-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding: 0.4rem 0;
}

.area-unit-wrapper::-webkit-scrollbar {
    width: 13px;
    background: #d9d9d9;
    border-radius: 10px;
}

.area-unit-wrapper::-webkit-scrollbar-thumb {
    background: var(--main-dark-color);
    border-radius: 15px;
    border: 3px solid transparent;
    background-clip: padding-box;
}

.area-section-heading {
    position: absolute;
    right: 71.5%;
    top: 100px;
    width: 190px;
    padding: 0.6rem 1.5rem;
    margin: 0;
    transform: rotate(-90deg);

    border-radius: 28px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    background-color: var(--main-dark-color);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
}

.area-section-search-field {
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 0.5rem;
    height: 31px;
    width: 330px;
    border-radius: 20px;
    color: var(--main-dark-color);
    border: 0px;
}

.area-section-search-field:focused {
    border: 2px solid var(--main-dark-color);
}

.area-button {
    box-sizing: border-box;
    background-color: var(--main-dark-color);
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    font-weight: 500;
}

.button-add-unit {
    margin-top: 0.8rem;
    padding: 10px 50px;
}

.button-delete-unit {
    width: 20px;
    height: 20px;
    background-image: url("../../Images/close-form-cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.button-filter-info {
    margin-left: 5px;
    background: var(--main-dark-color) url("../../Images/areas-filter-icon.svg") no-repeat center;
    width: 42px;
    height: 31px;
}

.button-add-direction-areas {
    height: 85vh;
}

.equip-type-icon {
    width: 30px;
    margin-right: 10px;
}

.equip-list-item {
    padding: 0;
}

.equip-item-wrapper {
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.filter-form {
    box-sizing: border-box;
    position: relative;
    position: absolute;
    top: 1rem;
    right: 2.7rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 341px;
    height: 170px;
    border-radius: 20px;
    background-color: var(--main-dark-color);
}

.filter-form-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.filter-close-form-button {
    position: absolute;
    top: 0.6rem;
    right: 0.8rem;
    background: url("../../Images/close-cross-white.svg") no-repeat center;
}

.filter-apply-button {
    color: var(--main-dark-color);
    background-color: var(--main-light-color);
    border-radius: 20px;
    padding: 0.3rem 2rem;
}

.filter-form-equip-button {
    border-radius: 50%;
    width: 36px;
    height: 36px;
}

.filter-form-button:active {
    border: 2px solid #ffffff;
}

.filter-form-staff-button {
    padding: 0px 10px;
    height: 30px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    color: var(--main-dark-color);
    background: var(--main-light-color);
}

.filter-button-excavator {
    background: var(--main-light-color) url("../../Images/Equipment/excavator_32.png") no-repeat center;
    background-size: 70% 70%;
}
.filter-button-bulldozer {
    background: var(--main-light-color) url("../../Images/Equipment/bulldozer_32.png") no-repeat center;
    background-size: 70% 70%;
}
.filter-button-crane {
    background: var(--main-light-color) url("../../Images/Equipment/crane_32.png") no-repeat center;
    background-size: 70% 70%;
}
.filter-button-grader {
    background: var(--main-light-color) url("../../Images/Equipment/grader_32.png") no-repeat center;
    background-size: 70% 70%;
}
.filter-button-rink {
    background: var(--main-light-color) url("../../Images/Equipment/rink_32.png") no-repeat center;
    background-size: 70% 70%;
}
.filter-button-truck {
    background: var(--main-light-color) url("../../Images/Equipment/truck_32.png") no-repeat center;
    background-size: 70% 70%;
}
.filter-button-auto {
    background: var(--main-light-color) url("../../Images/Equipment/auto_32.png") no-repeat center;
    background-size: 80% 80%;
}