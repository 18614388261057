@use './variables' as vars;

@mixin square_obj($size: 20px, $round-corners: 0px) {
  width: $size;
  height: $size;
  border-radius: $round-corners;
}

@mixin box($width, $height: $width, $radius: 0px) {
  width: $width;
  height: $height;
  border-radius: $radius;
}

@mixin one-hundred () {
  width: 100%;
  height: 100%;
}

@mixin min-max-width ($min, $max, $width: null) {
  min-width: $min;
  max-width: $max;
  @if $width {
    width: $width;
  }
}

@mixin min-max-height ($min, $max) {
  min-height: $min;
  max-height: $max;
}

@mixin pseudo-element($top: null, $bottom: null, $right: null, $left: null, $width: null, $height: $width) {
  content: "";
  position: absolute;
  @if $top {
    top: $top;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $right {
    right: $right;
  }
  @if $left {
    left: $left;
  }
  
  display: block;
  @if(width) {
    width: $width;
  }
  
  @if(height) {
    height: $height;
  }
}

@mixin rounded-corners($radius: vars.$border-radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin border ($width: 1px, $style: solid, 
$color: vars.$main-dark-color) {
  border: $width $style $color;
}

@mixin outline ($width: 1px, $style: solid, 
$color: vars.$main-dark-color, $offset: 0px) {
  outline-style: $style;
  outline-width: $width;
  outline-color: $color;
  outline-offset: $offset;
}

@mixin background-image (
  $color: transparent, $url: "", 
  $position: center, $size: 70%, 
  $repetition: no-repeat) {
    @if $url != "" {
    background-image: url($url);
    }
  background: {
    color: $color;
    position: $position;
    size: $size;
    repeat: $repetition;
  } 
}

@mixin flex ($direction: row, $justify: space-between, $align: stretch, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin fonts-basis ($size: vars.$font-size, $weight: 400, $color: null, $family: "Roboto", $line-height: normal) {
  font: {
    family: $family;
    size: $size;
    weight: $weight;
  }
  line-height: $line-height;
  color: $color;
}

@mixin font-size ($size, $line-height: normal, $letter-spacing: normal) {
  // font-size: $size * 1px;
  font-size: $size * 0.1rem;
  // example using rem values and 62.5% font-size so 1rem = 10px

  @if $line-height == normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $size;
  }

  @if $letter-spacing == normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $size}em;
  }
}