@use '../../../shared/styles/mixins' as *;
@use '../../../shared/styles/variables' as *;

.shift-table {
  &__warning-button {
    @include square_obj(33px);
    cursor: auto;
    @include background-image($url: '../../../shared/assets/warning-red-icon.svg', $size: 60%);
  }

  &__button {
    max-width: 350px;
    padding: 10px 20px;
    @include fonts-basis($size: $font-size-medium, $weight: 500);
    background-color: $main-light-color;
    @include rounded-corners(30px);
  }
}

.table-row-item__equipment-cell {
  p {
    padding-top: 25px;
  }
}