.block__title {
  position: relative;
  top: 0;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: #F0D07E;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.block__info-list,
.block__trips-title-list,
.block__trips-info-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.block__trips-title-list,
.block__trips-info-list {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
}

.block__info-list {
  position: relative;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.block__info-item {
  margin-bottom: 5px;
}

.block__trips-title-list {
  width: 95%;
  margin-bottom: 5px;
}

.block__info-item-title {
  color: rgb(0, 0, 0, 0.4);
}


.block__info-item-value {
  font-size: 11px;
  font-weight: 500;
  line-height: 21px;
}

.block__shift-place-info {
  position: relative;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.block__shift-place-info p {
  font-weight: 500;
  padding-left: 30px;
}

.block__shift-place-info p::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("../../Images/marker.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  content: "";
}

.block__shift-time-info {
  position: relative;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: 105px 1fr 105px;
  width: 80%;
}

.block__shift-time p {
  font-weight: 500;
  font-size: 11px;
}

.block__shift-time .block__info-item-title {
  font-weight: 400;
  margin-bottom: 5px;
}

.block__shift-time--right {
  text-align: right;
}

.block__shift-duration-info {
  position: relative;
}

.block__shift-duration-info::before {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 260px;
  height: 20px;
  background-image: url("../../Images/shift-duration-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  content: "";
}

.block__shift-duration-info p {
  margin-top: 30px;
  font-size: 9px;
  text-align: center;
}

.block__trips-info {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.block__trips-info > p {
  font-weight: 500;
  margin-bottom: 10px;
}

.block__trips-info-list-wrapper {
  height: 30vh;
  overflow-y: auto;
  margin-bottom: 40px;
}

.block__stops-info-list-wrapper {
  width: 80%;
  height: 20vh;
  margin: 0 auto;
  overflow-y: auto;
}

.block__trips-info-list-wrapper::-webkit-scrollbar,
.block__stops-info-list-wrapper::-webkit-scrollbar {
  width: 15px;
  background: #D4D2D6;
  border-radius: 10px;
  border: 1px solid #D4D2D6;
}

.block__trips-info-list-wrapper::-webkit-scrollbar-thumb,
.block__stops-info-list-wrapper::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.block__stops-loading {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}

.block__stops-not-existing {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 500;
  color: #F18072;
  text-align: center;
}

.block__stops-title-list,
.block__stops-info-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0;
  list-style-type: none;
}

.block__stops-title-list {
  position: relative;
  top: 20px;
  width: 80%;
  margin: 0 auto;
}

.block__stops-info-list-wrapper {
  position: relative;
  top: 25px;
}

.block__stops-duration {
  margin-top: 50px;
  padding: 20px 10%;
  font-size: 12px;
  background-color: #D4D2D6 ;
}

.block__controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
}

.block__controls label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.block__controls .control-mark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #211b27;
  border-radius: 5px;
}

.control-input[type="checkbox"]:checked + .control-mark {
  position: absolute;
  content: "";
  background-image: url("../../Images/tick1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border-radius: 4px;
}

.block__button-map-open {
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url("../../Images/open-map.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
}

.block__button-map-close {
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url("../../Images/close-map.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
}


@media (max-width: 1440px) {
  .block__info-list {
    width: 60%;
  }

  .block__shift-place-info {
    width: 60%;
  }

  .block__shift-time-info {
    width: 60%;
  }

  .block__trips-info {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .block__info-list {
    grid-template-columns: 1fr 1fr;
  }

  .block__info-item:first-child {
    grid-column: 1 / 3;
  }

  .block__info-item:nth-child(3),
  .block__info-item:nth-child(5) {
    text-align: right;
  }

  .block__shift-time-info {
    grid-template-columns: 1fr 1fr;
  }

  .block__shift-duration-info {
    order: 2;
    grid-column: 1 / 3;
  }

  .block__shift-duration-info::before {
    width: 150px;
    height: 20px;
    background-image: url("../../Images/shift-duration-icon-mobile.svg");
  }

  .block__info-list {
    width: 60%;
  }

  .block__shift-place-info {
    width: 60%;
  }

  .block__shift-time-info {
    width: 60%;
  }

  .block__trips-info {
    width: 60%;
  }

  .block__stops-info-list-wrapper {
    height: 60vh;
  }
}
