:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --liht-grey: #eeeeee;
  --darker-grey: #D4D2D6;
}

.table-head-filter-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-head {
  width: 100%;
  height: 20px;
  max-height: 30px;
  /* margin-bottom: 1rem; */
  padding: 12px 80px 8px 40px;
  font-size: 12px;
  background-color: var(--main-light-color);
  border-radius: 30px;
}

.table-head-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 12px 0 8px 0;
}

.table-head-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.table-head-mobile .table-head-list {
  margin-left: 10px;
}

.table-head-item p {
  margin: 0;
  text-align: center;
}

.table-head-item-mobile:not(:first-child) p {
  text-align: start;
}

.table-head-filter-wrapper-users .table-head-item-mobile:not(:first-child) p {
  text-align: start;
}

.table-head-item:first-child {
  width: 15%;
}

.table-head-item:nth-child(2) {
  width: 25%;
}

.table-head-item:nth-child(3) {
  width: 25%;
}

.table-head-item:last-child {
  width: 35%;
}

.table-head-item-mobile:first-child {
  width: 25%;
}

.table-head-filter-wrapper-equip .table-head-item-mobile:nth-child(2),
.table-head-filter-wrapper-equip .table-head-item-mobile:nth-child(3),
.table-head-filter-wrapper-equip .table-head-item-mobile:last-child {
  width: 50%;
}

.table-head-filter-wrapper-users .table-head-item:first-child {
  width: 30%;
}

.table-head-filter-wrapper-users .table-head-item:nth-child(2) {
  width: 20%;
}

.table-head-filter-wrapper-users .table-head-item:nth-child(3) {
  width: 25%;
}

.table-head-filter-wrapper-users .table-head-item:last-child {
  width: 25%;
}

.table-head-filter-wrapper-users .table-head-item-mobile:first-child {
  width: 30%;
}

.table-head-filter-wrapper-users .table-head-item-mobile:nth-child(2),
.table-head-filter-wrapper-users .table-head-item-mobile:nth-child(3),
.table-head-filter-wrapper-users .table-head-item-mobile:last-child {
  width: 49%;
}
