@use '../../../shared/styles/variables' as v;
@use '../../../shared/styles/mixins' as m;

.goto-arrow-btn {
  @include m.background-image($color: v.$main-dark-color, $url: "../../../shared/assets/actions/arrow-right-white-icon.svg", $size: 57%);
  // @include m.square_obj(3.5rem, 50%);
  // padding: 15px;
  @include m.rounded-corners(50%);
  padding: 17px;

  &_rotated-right-bottom {
    transform: rotate(45deg);
  }
  
  &_rotated-left-top {
    transform: rotate(-145deg);
  }

  &__black-icon {
    @include m.background-image($color: v.$main-light-color, $url: "../../../shared/assets/actions/arrow-right-black-icon.svg", $size: 57%);
  }
    
  &__white-icon {
    @include m.background-image($color: v.$main-dark-color, $url: "../../../shared/assets/actions/arrow-right-white-icon.svg", $size: 57%);
  }
} 