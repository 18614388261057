@use '../../../shared/styles/mixins' as *;
@use '../../../shared/styles/variables' as *;

.settings-modal {
  padding: 55px 82px;
  background-color: $main-dark-color;
  @include rounded-corners(30px);

  &-container {
    position: absolute;
    z-index: 200;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  &__page-size-editor {
    .field__label {
      margin-left: 0;
    }
  }

  .page-size-editor {
    position: relative;
    width: 60px;
    padding-block: 5px;
    padding-inline: 25px 4px;
    gap: 22px;

    &__button {
      margin: 0;
      padding: 0;
      @include fonts-basis($color: $main-dark-color, $size: 12px, $weight: 500);
      cursor: auto;
      background-color: transparent;
    }
  }

  .page-size-list {
    // border: 1px solid $white-color;
    top: -3px;
    right: -17px;

    button {
      padding: 6px 15px;
    }
  }

  .switcher-between-pages__button {
    width: 150px;
  }
}

.current-page-size {
  // background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: $main-dark-color;
  order: 1;
  
  button {
    color: #fff;
  }
}

.settings-modal__page-size-editor {
  // .page-size-item:first-child:hover {
  //   border-bottom-left-radius: 0px;
  //   border-bottom-right-radius: 0px;
  // }
  
  .page-size-item:last-child:hover,
  .page-size-item:last-child {
    // background-color: $main-light-color;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    
    // button {
    //   color: $main-dark-color;
    // }
  }
}