.download-message {
  position: absolute;
  left: 100px;
  z-index: 1;
  width: 160px;
  padding: 100px 40px 25px 40px;
  font-size: 11px;
  text-align: center;
  background-color: #F0D07E;
  border-radius: 20px;
  border-top-left-radius: 0;
  border: 4px solid #211B27;
}

.download-message-mobile {
  position: absolute;
  top: 30px !important;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  padding: 44vh 40px 33vh 40px;
  border-top-left-radius: 20px;
}

.logout-download-desktop .download-message {
  right: 0;
}

.download-mobile .download-message {
  right: 10px;
}

.download-message::after {
  position: absolute;
  top: -4px;
  left: -80px;
  z-index: 2;
  width: 76px;
  height: 55px;
  background-color: #F0D07E;
  background-image: url('../Images/CurrentMenu/apk-icon-current.svg');
  background-repeat: no-repeat;
  background-position: 20px 50%;
  background-size: 35%;
  border: 4px solid #211B27;
  border-right: none;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  content: "";
}

.download-message::before {
  position: absolute;
  top: 25px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: transparent;
  background-image: url("../Images/logo.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 5px;
  content: "";
}

.download-message-mobile::after {
  display: none;
}

.download-message-mobile::before {
  top: 30vh;
}

.message-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}
.centered-container {
  margin: auto;
}

.download-text {
  position: relative;
  margin: 0 auto;
  padding: 250px 0 0 0;
  font-size: 25px;
}

.download-text::before {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  background-color: transparent;
  background-image: url("../Images/logo.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 20px;
  content: "";
}

.download-successfull-message::before,
.download-successfull-mobile::before {
  background-color: #73D173;
}
.download-error-message::before,
.download-error-mobile::before {
  background-color: #E47F7F;
}
