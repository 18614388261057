:root {
  --main-dark-color: #211b27;
  --main-dark-color-shadow: rgba(33, 27, 39, 0.25);
  --main-light-color: #F0D07E;
}

.info-modal-container {
  position: relative;
  padding-top: 70px;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}

.info-modal-container__button--close {
  position: absolute;
  top: 0;
  right: -10px;
  width: 40px;
  height: 40px;
  background-image: url("../../Images/close-cross-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.info-modal-container__button-wrapper {
  position: absolute;
  display: none;
  top: 10px;
  left: 20px;
}

.info-modal-container__button--prev {
  width: 40px;
  height: 40px;
  background-image: url("../../Images/arrow-prev-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.info-modal-container__button--next {
  width: 40px;
  height: 40px;
  background-image: url("../../Images/arrow-next-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.info-modal-container__blocks-wrapper {
  width: 100%;
  /* height: 100%; */
}

.info-modal-container__blocks-inner-wrapper {
  width: 100%;
  height: 80vh;
  display: grid;
  grid-template-columns: 2.3fr 3fr;
  grid-template-rows:  1fr 1fr;
  gap: 40px;
}

.info-modal-wrapper__block {
  position: relative;
  background-color: #ffffff;
  border-radius: 30px;
  overflow-y: auto;
}

.info-modal-wrapper__block::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.info-modal-wrapper__block-info-shift {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.info-modal-wrapper__block-info-stops {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.info-modal-wrapper__block-map {
  position: relative;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.info-modal-wrapper__block-map .user-leaflet-container {
  width: 100%;
  height: 100%;
}

@media (max-width: 1440px) {
  .info-modal-container__blocks-wrapper {
    overflow-y: auto;
  }

  .info-modal-container__blocks-inner-wrapper {
    display: block;
    width: 97%;
  }

  .info-modal-container__blocks-wrapper::-webkit-scrollbar {
    width: 15px;
    background: #D4D2D6;
    border-radius: 10px;
    border: 1px solid #D4D2D6;
  }

  .info-modal-container__blocks-wrapper::-webkit-scrollbar-thumb {
    width: 10px;
    background: #211b27;
    border-radius: 15px;
    border: 3px solid transparent;
    background-clip: padding-box;
  }

  .info-modal-wrapper__block {
    margin-bottom: 40px;
  }

  .info-modal-wrapper__block:last-child {
    margin-bottom: 0;
  }

  .info-modal-wrapper__block-info-shift {
    height: 500px;
  }

  .info-modal-wrapper__block-info-stops {
    height: 250px;
  }

  .info-modal-wrapper__block-map {
   height: 250px;
  }
}

@media (max-width: 1000px) {
  .shift-container {
    margin: 0;
  }

  .info-modal-container {
    width: 100%;
    /* padding: 50px 20px 30px; */
    padding-top: 50px;
  }

  .info-modal-container__blocks-wrapper {
    overflow-y: hidden;
  }

  .info-modal-container__blocks-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .info-modal-container__blocks-inner-wrapper {
    width: 100%;
  }

  .info-modal-wrapper__block {
    height: 100%;
    margin-bottom: 0;
  }

  .info-modal-container__button-wrapper {
    display: block;
  }
}
