:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --light-grey: #eeeeee;
  --darker-grey: #D4D2D6;
  --error: #a7434f;
  --opened-form-width: 32vw;
}

.addition-main-index {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 95vh;
  width: 100%;
  max-height: 1200px;
}

.addition-main-index-mobile {
  flex-direction: column-reverse;
}

.addition-container {
  display: flex;
  height: 100%;
  margin: auto;
  width: 87vw;
  flex-grow: 1;
}

.addition-inner-container {
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 1.5rem 2.8rem 1.5rem 3rem;
  background-color: var(--main-dark-color);
  border-radius: 25px;
  border-right: 1px solid var(--main-dark-color);
}

.addition-main-index-mobile .addition-inner-container {
  height: 88%;
  padding: 0.5rem 1rem;
  margin-top: 60px;
  margin-bottom: 30px;
  overflow: hidden;
}

.addition-inner-header-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* width: 50%; */
  margin-top: 15px;
  margin-bottom: 30px;
}

.addition-inner-header-wrapper-mobile {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.addition-inner-container h1{
  margin: 0;
  margin-right: 40px;
  margin-left: 30px;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 400;
}

.addition-inner-header-wrapper-mobile h1 {
  width: 100%;
  margin-bottom: 15px;
  margin-left: 50px;
  font-size: 1.3rem;
}

.addition-wrapper {
  position: relative;
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 1rem;
  height: 80%;
  /* width: 68vw; */
  /* max-width: 1450px; */
  /* margin-top: 25px; */
  transition: width 1s ease;
}

.addition-wrapper__button-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  display: flex;
}

.addition-wrapper__button-wrapper button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 50%; */
}

.addition-wrapper__button-prev {
  background-image: url('../../Images/arrow-prev.svg');
}

.addition-wrapper__button-next {
  background-image: url('../../Images/arrow-next.svg');
}

.addition-wrapper__button-prev-white {
  background-image: url('../../Images/arrow-prev-white.svg');
}

.addition-wrapper__button-next-white {
  background-image: url('../../Images/arrow-next-white.svg');
}

.addition-container.collapsed {
  width: calc(90vw - var(--opened-form-width));
}

.switcher-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 30%; */
}

.addition-inner-header-wrapper-mobile .switcher-container {
  margin-left: 20px;
  width: 100%;
}

.filter-container {
  position: relative;
  width: 96.5%;
  height: 50px;
  font-size: 11px;
  background-color: var(--darker-grey);
  border-radius: 30px;
}

.filter-container__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 50px;
  background-color: var(--main-light-color);
  background-image: url('../../Images/filter-icon-black.svg');
  background-repeat: no-repeat;
  background-position: top 5px right 20px;
  background-size: 40%;
  border-radius: 30px;
}

.filter-container__categories-wrapper {
  position: absolute;
  top: 1px;
  left: 3px;
  z-index: 1;
  width: 300px;
  margin: 0;
  color:#ffffff;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}

.filter-container__applied-filter-wrapper {
  position: absolute;
  top: 0;
  left: 80px;
  display: flex;
  width: 90%;
  padding-right: 50px;
  height: 50px;
  overflow-x: auto;
}

.addition-main-index-mobile .filter-container__applied-filter-wrapper {
  width: 90%;
}

.filter-container__applied-filter-wrapper::-webkit-scrollbar,
.filter-container__applied-filter-wrapper::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
}

.filter-container__applied-filter-category {
  display: flex;
  height: 50px;
}

.filter-container__applied-filter {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 10px 30px 30px;
  border-radius: 30px;
}

.filter-container__button-delete-filter {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  background-color: transparent;
  background-image: url('../../Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.categories__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.categories__items button {
  width: 100%;
  padding: 15px 30px;
  color: #ffffff;
  background-color: transparent;
  border: none;
}

.categories__items:first-child button {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.categories__items:last-child button {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.categories__items button:hover{
  background-color: #9E95A5;
}

.button-apply-equipment {
  width: 38px;
  height: 38px;
  margin-right: 5px;
  margin-bottom: 10px;
  background-color: #211B27;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-size: 70%;
}

.button-apply-equipment:active,
.button-apply-equipment:focus {
  outline: 2px solid #ffffff;
}

.table-row-wrapper {
  position: absolute;
  top: 90px;
  left: 0;
  margin-bottom: 1rem;
  width: 100%;
  height: 95%;
  /* display: flex;
  justify-content: space-between; */
  overflow-y: auto;
  overflow-x: hidden;
}

.addition-main-index-mobile .table-row-wrapper {
  height: 80%;
}

.table-row-wrapper::-webkit-scrollbar {
  width: 15px;
  background: #d9d9d9;
  border-radius: 10px;
}

.table-row-wrapper::-webkit-scrollbar-thumb {
  width: 10px;
  background: var(--main-dark-color);
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.addition-main-index-mobile .table-row-wrapper::-webkit-scrollbar-thumb,
.addition-main-index-mobile .table-row-wrapper::-webkit-scrollbar {
  width: 0;
}

.loading-data-block {
  width: 96%;
  height: 80%;
  padding: 40px 0;
  background-color: var(--light-grey);
  border-radius: 30px;
}

.loading-data-block p {
  font-size: 20px;
  text-align: center;
}

/* ADDITION FORMS */

.button-add {
  width: 70px;
  height: 95vh;
  max-height: 1200px;
  margin: 0 1vw;
  font-size: 40px;
  transform-origin: right;
  color: #ffffff;
  background-color: #211b27;
  border-radius: 25px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.25);
  transition: width 1s ease;
}

.button-add.open {
  width: var(--opened-form-width);
  max-width: 1500px;
  background-color: rgba(33, 27, 39, 1);
  color: rgba(33, 27, 39, 1);
}

.addition-main-index-mobile .button-add {
  position: absolute;
  top: 0;
  width: 90vw;
  height: 50px;
  border-radius: 30px;
  transition: height 1s ease;
}

.addition-main-index-mobile .button-add.open {
  height: 85vh;
  z-index: 2;
}

.addition-form-wrapper {
  position: absolute;
  right: 2.6vw;
  top: 10vh;
  z-index: 2;
  height: 82vh;
  /* padding: 2.5rem 1.5rem; */
  background-color: transparent;
  border-radius: 25px;
  /* padding: 0.5rem; */
  /* width: calc(var(--opened-form-width) - 10vw); */
  width: 22vw;
}

.addition-main-index-mobile .addition-form-wrapper {
  right: 7vw;
  width: 80vw;
  height: 80vh;
}

.addition-form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 .5rem;
}

.addition-form label {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: .7rem;
  margin-left: 5px;
}

.addition-form::-webkit-scrollbar {
  width: 8px;
  background: #D4D2D6;
  border-radius: 10px;
  border: 1px solid var(--darker-grey);
}

.addition-form::-webkit-scrollbar-thumb {
  width: 5px;
  background: #211b27;
  border-radius: 15px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.addition-form-wrapper label {
  margin: 0;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 15px;
}

.additional__form-message__wrapper {
  box-sizing: border-box;
  position: relative;
  position: absolute;
  bottom: 52px;
  /* border-radius: 10px; */
  right: 250px;
  z-index: 20;
  width: 300px;
  height: 80px;
  text-align: center;
  vertical-align: baseline;
  opacity: 0;
  background-color: var(--error);
  transition: opacity 0.2s ease;
  clip-path: polygon(90% 0, 91% 62%, 97% 100%, 84% 81%, 0 81%, 0 0);
}

.additional__form-message__wrapper .additional__form__error-message-note {
  padding: 0;
  margin: 22px 30px 5px 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.additional__form-message__wrapper.showed {
  opacity: 1;
}

.addition-main-index-mobile .addition-form {
  height: 85%;
  overflow-y: auto;
}

.addition-main-index-mobile .addition-form::-webkit-scrollbar,
.addition-main-index-mobile .addition-form::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
}

.addition-form__input-wrapper {
  /* margin-top: 1.3rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addition-form__input-wrapper:not(:first-child) {
  margin-top: 1.3rem;
}

.addition-form-input {
  box-sizing: border-box;
  padding: 1.5rem .5rem;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: var(--main-dark-color);
}

.addition-form-input.dirplaces-input {
  padding: 0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-bottom: .5rem;
  background-color: #F0D07E;
  border-radius: 25px;
  box-shadow: 0px 4px 10px 1px #00000040;
}

.addition-button {
  margin-right: 0.3rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0.8rem;
  font-size: 13px;
  border-radius: 23px;
  background-color: #F0D07E;
  color: #211b27;
  font-weight:500;
}
  /* .button-add-staff-member-type:active,
  .button-add-staff-member-type:focus {
    outline: 2px solid #ffffff;
  } */

.addition-form .addition-button-type-list {
  margin-top: 0;
  /* margin-bottom: 1rem; */
  justify-content: flex-start;
  gap: 0px;
}

.addition-button-type-list.addition-equipment-button-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.addition-button-type-list.addition-user-equipment-button-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.addition-button-add-equipment {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: .3rem;
    margin-bottom: .3rem;
    background-color: #F0D07E;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
    border-radius: 50%;
}

.technic-type-white-pooker {
  background-size: 50%;
}

.addition-button.selected,
.addition-button-add-equipment.selected {
  outline: 4px solid #fff;
  background-color: var(--main-dark-color);
  color:#fff;
}

.modal-change-equipment.selected {
  background-color: var(--main-dark-color);
}

.addition-modal__input.existing-detector-input {
  background-color: var(--main-light-color);
  font-size: 16px;
}

.dirplaces-list-container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  /* height: 100vh; */
}

.dirplaces {
  box-sizing: border-box;
  position: absolute;
  /* top: calc(80% - 20%); */
  top: 60%;
  right: 0.2vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 0;
  font-weight: 500;
  overflow: hidden;
  background-color: #F0D07E;
  /* border-radius: 30px; */
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: height 1.5s ease;
}

.dirplaces.open {
  /* height: 40vh; */
  height: calc(100px + (350 - 100) * ((100vh - 500px) / (1080 - 500)));
  /* width: 310px; */
  padding: 0.5rem;
  background-color: #F0D07E;
}

.places {
  box-sizing: border-box;
  /* position: absolute; */
  /* top: calc(80% - 20%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  font-weight: 500;
  overflow: hidden;
  background-color: #fff;
  color: var(--main-dark-color);
  border-radius: 20px;
  /* border-bottom-left-radius: 30px; */
  /* border-bottom-right-radius: 30px; */
  transition: height 1.5s ease;
}

.places.open {
  height: 25vh;
  /* width: 310px; */
  padding: 0.3rem;
  /* background-color: #F0D07E; */
}

.addition-main-index .button-close-form {
  right: 2.5vw;
  top: 3vh;
}

.addition-main-index-mobile .button-close-form {
  right: 10vw;
}

.dirplaces-list-wrapper {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  /* margin: 0 10rem; */
  z-index: 4;
  overflow-y: auto;
  overflow-x: hidden;
}

.places-list-wrapper {
  width: 100%;
  height: 95%;
  /* margin-top: .1rem; */
  /* margin: 0 10rem; */
  z-index: 4;
  overflow-y: auto;
  overflow-x: hidden;
}

.dirplaces-list-item-heading {
  font-size: 12px;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--main-dark-color);
}

.dirplaces-list-item-heading-places {
  font-size: 11px;
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-top: 0.6rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem;
}

.dirplaces-list-item-heading-direction {
  font-size: 12px;
  font-weight: 600;
  border: none;
  margin-left: 10px;
}

.dirplaces-list {
  width: 95%;
  padding: 0;
  padding-right: 5px;
  margin-bottom: 0.5rem;
  list-style-type: none;
  max-height: 290px;
}

.dirplaces-list__wrapper {
  position: relative;
  /* display: flex; */
  width: 100%;
}

/* .dirplaces-list-places {
  overflow-y: auto;
  position: absolute;
  margin-bottom: 1rem;
  margin-left: 1rem;
  width: 100%;
  height: 0;
} */

.dirplaces-list .dirplaces-list-item {
  /* position: relative; */
  cursor: pointer;
  margin: 0.2rem 0;
  padding: 0.6rem 1rem;
  font-size: 11px;
  font-weight: 500;
  border-radius: 30px;
  border-width: 3px;
  border-style: solid;
  z-index:1;
  border-color: var(--darker-grey);
  background-color: #ffffff;
  /* width: 100%; */
}

.places-list-item {
  /* position: relative; */
  cursor: pointer;
  margin: 0.2rem 0;
  padding: 0.6rem 1rem;
  font-size: 11px;
  font-weight: 500;
  border-radius: 30px;
  border-width: 3px;
  border-style: solid;
  z-index: 40;
  border-color: var(--darker-grey);
  background-color: #ffffff;
  /* width: 100%; */
  color:var(--main-dark-color);
}

.button-close-places,
.button-open-places {
  position: relative;
  position: absolute;
  box-sizing: border-box;
  right: 3px;
  top: 3px;
  width: 34px;
  height: 34px;
  /* background-color: blue; */
  /* padding: 16px; */
  border-radius: 17px;
  z-index: 10;
}

.button-close-places:hover,
.button-open-places:hover {
  background-color: var(--darker-grey);
}

.button-close-places::after,
.button-open-places::after {
  content: '';
  position: absolute;
  z-index: 10;
  border: 6px solid transparent;
}

.button-close-places::after {
  left: 32%;
  top: 20%;
  border-bottom: 8px solid var(--main-dark-color);
}

.button-open-places::after {
  left: 35%;
  top: 40%;
  border-top: 8px solid var(--main-dark-color);
}

.dirplaces-list .dirplaces-list-item:hover {
  background-color: var(--darker-grey);
  border-color: #c2c2c4;
}

.dirplaces-list-item.selected-item,
.dirplaces-list-item.selected-item:hover {

  border-color: var(--main-dark-color);
  background-color: var(--main-dark-color);
  color: #ffffff;
}

/* .dirplaces-list-item.selected-item ~ .places-list__button::after {
  border-color: #fff;
} */

.dirplaces-list-item.selected-item ~ .button-close-places::after {
  left: 35%;
  top: 20%;
  border-bottom: 8px solid #fff;
}

.dirplaces-list-item.selected-item ~ .button-open-places::after {
  left: 35%;
  top: 40%;
  border-top: 8px solid #fff;
}

.dirplaces-list-item.opened-list,
.places-list__button.opened-list {
  z-index:200;
}

.places-list-wrapper::-webkit-scrollbar,
.dirplaces-list-wrapper::-webkit-scrollbar {
  width: 12px;
  background: var(--light-grey);
  border-radius: 10px;
  border: 1px solid var(--darker-grey);
}

.places-list-wrapper::-webkit-scrollbar {
  width: 8px;
}

.places-list-wrapper::-webkit-scrollbar-thumb,
.dirplaces-list-wrapper::-webkit-scrollbar-thumb {
  width: 10px;
  background: var(--main-dark-color);
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.dirplaces-list-note {
  padding-left: 0.4rem;
  margin-top: 1.5rem;
  font-weight: 500;
  font-size: 10px;
  color: var(--main-dark-color);
  border-left: 3px solid var(--main-dark-color);
}

.dirplaces-empty-list-note {
  margin-left: 1.5rem;
}

.chosen-dirplace {
  margin-top: 1rem;
  color: #ffffff;
  /* font-weight: 500; */
  font-size: 16px;
}

.chosen-dirplace p {
  margin: 0.3rem 0;
  padding: 0;
}

.chosen-dirplace .chosen-dirplace-text {
  display: inline-block;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  background-color: #ffffff;
  color: var(--main-dark-color);
}

.addition-submit-__wrapper {
  /* position: relative; */
  margin-top: auto;
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* margin-bottom: 30px; */
}

/* .addition-form__submit__error-message {
  position: absolute;
  top: -25px;
  left:0;
} */

.button-submit-direction {
  width: 100%;
  margin-top: auto;
  /* align-self: center; */
}

.validation-error-message {
  color: var(--error);
  /* padding: 0.3rem; */
  margin-left: .3rem;
  font-weight: 600;
  font-size: 12px;
}

.form-submitted-equipment__wrapper {
  margin-top: 80%;
  font-size: 20px;
}

.addition-edit-modal {
  position: relative;
}

.addition-change__button {
  position: absolute;
  top: 8px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--main-dark-color) url("../../Images/arrow-open-list.svg") no-repeat center;
  background-size: 45%;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.addition-change-modal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 6px;
  right: 7px;
  z-index: 10;
  background-color: var(--main-dark-color);
  width: 90%;
  max-width: 350px;
  border-radius: 20px;
  overflow: hidden;
  color: var(--main-dark-color);
}

.users-modal-option {
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 11px;
  color: var(--light-grey);
}

.users-modal-option:hover {
  background-color: #9E95A5;
}

.addition-label{
  margin: 1.5rem 1rem;
  padding: 0 1rem;
  /* width: 100%; */
}

.addition-modal__input {
  width: 240px;
  padding: 0.7rem 1.5rem;
  background-color: #ffffffff;
  font-size: 11px;
  border-radius: 20px;
}

.new-detector__input {
  /* width: 255px; */
  /* margin: 3rem 0.5rem 0; */
  text-align: center;
  background-color: var(--light-grey);
}

.addition-modal__button {
  padding: 0.5rem 1.5rem;
  /* margin-top: 20px; */
  font-size: 11px;
  margin: 1rem 20% 0rem;
  min-width: 150px;
  font-weight: 500;
  background-color: var(--main-light-color);
  border-radius: 20px;
}

.modal-change-equip {
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin-top: 2rem;
  /* margin-bottom: 0rem; */
  /* margin: 3rem 0rem 1.5rem; */
  gap: 0.5rem;
}

.addition-modal-equipment-list .addition-button-add-equipment {
  width: 40px;
  height: 40px;
}

.detector-editor-note__wrapper .detector-editor-note {
  margin: 2rem 1.5rem 1.5rem;
  color: #60b42f;
  font-size: 16px;
  font-weight: 400;
}

.modal-delete-note {
  padding-top: 0.5rem;
  margin: 1rem 2rem;
  color: var(--error);
  font-size: 15px;
  font-weight: 500;
}

.modal-delete__buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin:  0 40px 20px;
  /* width: 100%; */
}

.modal-delete__button {
  padding: 0.5rem 1rem;
  width: 150px;
  border-radius: 20px;
  font-weight: 500;
}

.modal-delete__button-disgard {
  background-color: var(--main-light-color);
  color: var(--main-dark-color);
  margin-right: 20px;
}

.modal-delete__button-accept {
  background-color: #c7c7c7;
  color: #ffffff;
}

/* .addition-button-add-equipment.technic-type-white-* {
  background-color: var(--main-dark-color);
} */

.addition-close-modal__button {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 0.4rem;
  background: url("../../Images/close-cross-white.svg") no-repeat center;
  background-size: 100%;
}

.addition-modal__error-message__wrapper {
  box-sizing: border-box;
  position: relative;
  position: absolute;
  top: 65px;
  right: 346px;
  z-index: 20;
  width: 300px;
  height: 100px;
  text-align: center;
  vertical-align: baseline;
  opacity: 0;
  background-color: var(--error);
  transition: opacity 0.2s ease;
  clip-path: polygon(0 18%, 84% 17%, 100% 0, 91% 41%, 91% 100%, 0 100%);
}

.addition-modal__error-message__wrapper .addition-modal__error-message-note {
  padding: 0;
  margin: 40px 18px 5px 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.addition-modal__error-message__wrapper.showed {
  opacity: 1;
}
.addition-form-wrapper .button-close-analytics,
.addition-form-wrapper .button-open-analytics {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: .3rem;
  background-size: 50%;
}

.addition-form__password-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.addition-form__password-input-wrapper input {
  flex-grow: 1;
}

.addition-form__bth-generate-password {
  padding: .5rem .7rem;
  background-color: var(--main-light-color);
  text-align: center;
  font-weight: 500;
  border-radius: 25px;
  margin-left: .3rem;
}

.modal-window__editing-modal-window {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
}

.modal-window__input {
  font-family: "Roboto";
  font-size: 18px;
  width: 80%;
  padding: .7rem 1.5rem;
  border-radius: 25px;
  margin-bottom: .5rem;
}

@media (max-width: 1800px) {
  .addition-form-input {
    padding: .5rem .5rem;
    /* margin-bottom: .5rem; */
  }

  .dirplaces-list .dirplaces-list-item {
    position: relative;
    padding: 0.3rem 0.5rem;
    font-size: 12px;
  }

}

@media (max-width: 1600px) {

  .addition-form label,
  .dirplaces-list-item-heading  {
    font-size: 18px;
    /* margin-bottom: .2rem; */
  }
  .addition-inner-header-wrapper {
    display: flex;
    /* width: 50%; */
    align-items: center;
  }

  .addition-form__input-wrapper:not(:first-child) {
    margin-top: .5rem;
  }

  .dirplaces-list-wrapper {
    margin-top: 1rem;
  }

  .dirplaces-list-note {
    font-size: 11px;
  }

  .button-close-form {
      width: 35px;
      height: 35px;
  }

  .addition-form-input {
    padding: .5rem .5rem;
    /* margin-bottom: .5rem; */
  }

  .addition-form-input.dirplaces-input {
    padding: 0.2rem .5rem;
  }

  .chosen-dirplace {
    font-size: 12px;
  }

  .dirplaces-list-item-heading-direction {
    font-size: 15px;
    margin: 0.3rem;
    padding: 0;
  }

  .button-close-places,
  .button-open-places {
    right: 2px;
    top: 2px;
    width: 26px;
    height: 26px;
    border-radius: 17px;
  }

  .button-close-places::after,
  .button-open-places::after {
    content: '';
    position: absolute;
    z-index: 10;
    border: 4px solid transparent;
  }

  .button-close-places::after {
    left: 35%;
    top: 20%;
    border-bottom: 8px solid var(--main-dark-color);
  }

  .button-open-places::after {
    left: 35%;
    top: 40%;
    border-top: 8px solid var(--main-dark-color);
  }

  .dirplaces-list-item-heading-places,
  .dirplaces-empty-list-note
  /* .dirplaces-list-places */
   {
    font-size: 10px;
    margin-top: .2rem;
    margin-bottom: .2rem;
  }

  .button-submit-direction {
    padding: 0.8rem 1rem;
  }

  .validation-error-message {
    font-size: 10px;
  }
}

@media (max-width: 1400px) or (max-height: 750px) {
  .addition-form-wrapper {
    width: 21.5vw;
  }

  .addition-form label {
    font-size: 12px;
  }

  .addition-form label,
  .dirplaces-list-item-heading  {
    /* font-size: 18px; */
    margin-bottom: .2rem;
  }

  .addition-user-equipment-button-list .addition-button-add-equipment {
    width: 2rem;
    height: 2rem;
  }

  .addition-form,
  .addition-form input,
  .addition-form button {
    font-size: 9px;
  }

  .addition-form-input {
    font-size: 9px;
  }
}
