@use '../../../shared/styles/variables' as v;
@use '../../../shared/styles/mixins' as m;

.edit-btn {
  @include m.background-image($color: v.$main-dark-color, $url: "../../../shared/assets/actions/edit-white-icon.svg", $size: 50%);
  @include m.square_obj(27px, 50%);
  box-shadow: 0px 5px 8px 0px v.$main-dark-color-shadow;

  &__black-icon {
  @include m.background-image($color: v.$main-light-color, $url: "../../../shared/assets/actions/edit-black-icon.svg", $size: 50%);
  }
  
  &__white-icon {
  @include m.background-image($color: v.$main-dark-color, $url: "../../../shared/assets/actions/edit-white-icon.svg", $size: 50%);
  }
}