:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --grey-color: #D4D2D6;
}

.modal-delete-container {
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.4);
}

.modal-delete-wrapper {
  margin: auto;
  padding: 40px 110px;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}

.modal-delete-wrapper p {
  margin: 0;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.modal-delete-buttons-block {
  display: flex;
  justify-content: space-between;
  width: 460px;
}

.button-modal-delete {
  padding: 12px 73px;
  font-size: 15px;
  line-height: 18px;
  border-radius: 30px;
}

.button-modal-delete.button-reverse-deletion {
  background-color: var(--grey-color);
}

.button-modal-delete.button-deletion {
  background-color: var(--main-light-color);
}
