:root {
  --main-dark-color: #211b27;
  --main-dark-color-shadow: rgba(33, 27, 39, 0.25);
  --main-light-color: #F0D07E;
}

.faq-main-container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  height: 95vh;
  max-width: 2200px;
  max-height: 1200px;
  background-color: var(--main-dark-color);
  color: #fff;
  border-radius: 30px;
  padding: 1.5rem 3rem 1rem;
}

.faq-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.faq-nav {
  display: flex;
  align-items: center;
}

.faq-nav h1 {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-right: 1rem;
}

.faq-nav__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-nav__list_header {
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  position: relative;
  width: 90px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 35px;
  background-color: var(--main-light-color);
  cursor: pointer;
}

.faq-nav__list_header::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  width: 33px;
  height: 33px;
  background: url("../../Images/arrow_down.svg") center/100% no-repeat;
}

.faq-nav__list_img {
  width: 36px;
  height: 25px;
}

.faq-nav__list_header .faq-nav__list_img {
  position: absolute;
  top: 7px;
  left: 18px;
}

.faq-nav__list {
  position: absolute;
  top: 2.3rem;
  box-sizing: border-box;
  width: 90px;
  padding: 1.5rem .7rem 0rem;
  margin-top: -1rem;
  z-index: 0;
  background-color: var(--main-light-color);
  list-style: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.faq-nav__item {
  cursor: pointer;
  padding: 0 1rem 0.5rem;
}

.faq-nav__item:not(:last-child) {
  /* border-bottom: 2px solid var(--main-dark-color); */
  margin-bottom: .5rem;
}

.navigation-list-table {
  background: url("../../Images/CurrentMenu/shift-table-icon-current.svg") center/contain no-repeat;
}

.navigation-list-addition {
  background: url("../../Images/CurrentMenu/addition-page-icon-current.svg") center/contain no-repeat;
}

.navigation-list-tracker {
  background: url("../../Images/CurrentMenu/tracker-icon-current.svg") center/contain no-repeat;
}

.navigation-list-service {
  background: url("../../Images/CurrentMenu/service-icon-current.svg") center/contain no-repeat;
}

.faq__close-btn {
  width: 65px;
  height: 65px;
  background: var(--main-light-color) url("../../Images/close-form-cross.svg") center/55% no-repeat;
  border-radius: 50%;
}

.faq-main {
  margin: 1rem 1rem 1rem 0rem;
  overflow-y: auto;
  font-size: 18px;
}

.faq-main__wrapper {
  height: 100%;
  width: 100%;
}

.faq-main__subtitle { 
  margin-bottom: 1rem;
  font-size: 24px;
}

.faq-main::-webkit-scrollbar {
  width: 15px;
  background: #D4D2D6;
  border-radius: 10px;
  border: 1px solid var(--darker-grey);
}

.faq-main::-webkit-scrollbar-thumb {
  width: 10px;
  background: var(--main-dark-color);
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.faq-main__item:not(:last-child) {
  margin-bottom: 1.3rem;
}

.faq-main__item img {
  vertical-align: middle;
}

.faq-main__item_question_wrapper {
  display: flex;
  align-items: center;
}

.faq-main__item_question {
  cursor: pointer;
  color: var(--main-light-color);
}

.open-btn {
  margin-left: .5rem;
  width: 30px;
  height: 30px;
  background: url("../../Images/arrow_down-yellow.svg") no-repeat center;
  background-size: 100%;
  transition: transform 0.1s ease;
}

.open-btn a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.open-btn.open {
  transform: rotate(-180deg);
}

.faq-main__item_answer {
  line-height: 30px;
}