@use "../../../shared/styles/mixins.scss" as m;
@use "../../../shared/styles/variables.scss" as v;

.statuses-list {
  position: relative;
  width: 21rem;
  height: 3.5rem;
  @include m.fonts-basis($size: 1.6rem, $weight: 500);

  &__field {
    position: relative;
    @include m.flex();
    @include m.rounded-corners(20px);
    padding: 0.8rem 1.5rem;
    box-shadow: 0px 4px 10px 0px #00000040;
    z-index: 20;
    color: v.$main-dark-color;
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 0rem;
    padding-top: 2rem;
    z-index: 10;
    width: 100%;
    @include m.rounded-corners(15px);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: v.$main-light-color;
    @include m.fonts-basis(
      $size: 1.6rem,
      $weight: 500,
      $color: v.$main-dark-color
    );

    // &--black {
    // background-color: v.$main-dark-color;
    // }
  }

  &__item {
    padding: 1rem 1.5rem;
    cursor: pointer;
    @include m.flex($justify: start, $align: center);

    .detailed-task-header-status-field__img {
      padding-right: 1.3rem;
    }

    &:hover {
      background-color: #f0e4bb;
    }

    &:last-child {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  &__img {
    @include m.square_obj();
  }

  &__text {
    margin: 0 0.5rem;
  }
}

.statuses-list--dark-mode {
  background-color: v.$main-dark-color;
  color: v.$white-color;

  li:hover {
    background-color: v.$main-dark-color-hover;
  }

  &__field {
    background-color: v.$main-dark-color;
    color: v.$white-color;
    &_expanded {
      background-color: v.$main-dark-color-hover;
    }
  }
}
