:root{
  --main-dark-color: #211B27;
}

.task-form-container {
  position: absolute;
  top: 130px;
  /* left: 70px; */
  right: 340px;
  z-index: 6;
  width: 900px;
  padding: 55px 95px;
  /* background-color: var(--main-dark-color); */
}

.modal-close-transparent{
  position: absolute;
  top: 130px;
  left: 1070px;
  z-index: 7;
  width: 80px;
  height: 80px;
  background-image: url("../../Images/close-cross-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.add-task-form {
  display: grid;
  grid-template-columns: 400px 90px 400px;
  grid-template-rows: repeat(5, 90px);
  row-gap: 23px;
}

.add-task-form label {
  font-size: 16px;
  color: #fff;
}

.task-form-input {
  width: 360px;
  height: 54px;
  margin-top: 10px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 20px;
}

.task-form-input::placeholder {
  font-size: 12px;
}

.task-form-input-date,
.task-form-input-task-capacity {
  width: 140px;
}


.field-group {
  position: relative;
  margin: 0;
  margin-top: 25px;
}

.add-task-form .field-group-direction,
.add-task-form .field-group-staff-members,
.add-task-form .field-group-area,
.add-task-form .field-group-task,
.add-task-form .field-group-task-сapacity {
  grid-column: 1 / 2;
}

.add-task-form .field-group-equipment,
.add-task-form .field-group-date,
.add-task-form .field-group-description,
.add-task-form .field-group-submit {
  grid-column: 3 / -1;
}

.add-task-form .fiel-group-area {
  grid-row: 3 / 4;
}

.add-task-form .field-group-description {
  grid-row: 3 / 5;
}

.input-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field-group textarea {
  width: 400px;
  height: 165px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.field-group .analytics-item,
.field-group .equipment-item {
  height: 50px;
  margin-top: 15px;
}

.field-group .staff-members-list {
  top: 40px;
  width: 360px;
}

.field-group .staff-members-list.open {
  top: 40px;
  width: 360px;
}

.field-group .equipment-list {
  top: 40px;
  width: 360px;
}

.field-group .equipment-list.open {
  top: 40px;
  width: 360px;
}

.button-submit-task {
  margin: 0;
  margin-top: 10px;
  padding: 15px 100px;
  font-size: 16px;
  font-weight: 500;
  background-color: #F0D07E;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 1px #00000040;
}

.button-open-tasks-list {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  background-color: #F0D07E;
  background-image: url("../../Images/tasks-list.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border-radius: 50%;
}
