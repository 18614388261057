@use "../../../shared/styles/variables" as v;
@use "../../../shared/styles/mixins" as m;


.form {
  @include m.flex($direction: column);
  // position: absolute;
  // right: 1vw;
  // top: 0;
  width: 503px;
  // height: 95vh;
  padding: 5.5rem 6rem 5.5rem; 
  color: v.$white-color;
  border-radius: v.$border-radius;

  // background-color: v.$darker-grey-color;

  &__button {
    box-sizing: border-box;
    @include m.rounded-corners();
    padding: 0.6rem 1.5rem;
    cursor: pointer;
    width: fit-content;
    // font-size: 1.8rem;
    background-color: v.$main-light-color;
    color: v.$main-dark-color;

    &_selected {
      @include m.outline(4px, solid, v.$white-color);
      color: v.$white-color;
      background-color: v.$main-dark-color;
    }
  }
}

.field {
  @include m.flex(column);
  margin-bottom: 2.5rem;
  width: 100%;

  &__label {
    margin-left: 1.5rem;
    @include m.fonts-basis($color: v.$white-color, $size: v.$font-size-big, $weight: 500);
    margin-bottom: 0.9rem;
  }
  
  &__input {
    padding: 1rem 1.4rem;
    font-size: v.$font-size-medium;
    border: none;
    @include m.rounded-corners();

    &::placeholder {
      font-size: v.$font-size-small;
      font-weight: 500;
      line-height: 0;
      color: v.$placeholder-color;
    }
  }
}

.form__error-message {
  @include m.fonts-basis(12px, 500, v.$error-dark-color);
  margin-left: 1rem;
  margin-top: 0.5rem;
}

#form__error-message {
  @include m.fonts-basis(12px, 500, v.$error-dark-color);
  text-align: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
}