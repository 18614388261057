@use './variables.scss' as vars;

body {
  margin: 0;
}

button {
  border: none;
  cursor: pointer;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input {
  border: none;
  padding: 0;
}