@use '../../../shared/styles/variables' as vars;
@use '../../../shared/styles/mixins' as m;

.expand-btn {
  @include m.background-image($url: "../../../shared/assets/actions/arrow_down-black-icon-24.svg", $size: 90%);
  @include m.box(20px, 20px, 50%);
  // padding: 1.7rem; 
  
  &_white {
  @include m.background-image($url: "../../../shared/assets/actions/arrow_down-white-icon.svg", $size: 90%);
  }

  &_black {
  @include m.background-image($url: "../../../shared/assets/actions/arrow_down-black-icon-24.svg", $size: 90%);
  }

  transition: all 0.5s;

  &--expanded {
    transform: rotate(180deg);
  }
}