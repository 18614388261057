:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --grey-color: #D4D2D6;
}

.modal-window {
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.4);
}

.modal-window__wrapper {
  position: relative;
  margin: auto;
  background-color: var(--main-dark-color);
  border-radius: 30px;
  padding: 2rem 3rem;
}

.modal-window__wrapper p {
  margin: 0;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.modal-window__buttons-block {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 1rem auto 0;
}

.modal-window__button_delete {
  padding: 1rem 1.5rem;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 18px; */
  border-radius: 30px;
}

.modal-window__button_delete.button_reverse_deletion {
  background-color: var(--grey-color);
}

.modal-window__button_delete.button_deletion {
  background-color: var(--main-light-color);
}

.modal-window__button-close {
  position: absolute;
  top: 5%;
  right: 2%;
  width: 36px;
  height: 36px;
  background: transparent url("../Images/close-cross-white.svg") no-repeat center;
  background-size: 70%;
  border-radius: 10px;
}

.modal-window__button-close:hover {
  background-color: rgba(255, 255, 255, 0.3)
}

.modal-window_note {
  color: #fff;
}

.modal-window_note__wrapper {
  padding: 3rem 2rem 1em;
}

.modal-window_note__note {
  font-size: 20px;
  margin-bottom: 1.5rem;
}

.modal-window_note__label {
  position: relative;
  display: inline-flex;
}

.modal-window_note__section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* margin: 0 auto; */
  height: 100%;
}

.modal-window_note__input {
  /* width: 50%; */
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.modal-window__copy-button {
  position: absolute;
  display: block;
  top: 5px;
  right: 0px;
  content: "";
  z-index: 1;
  width: 25px;
  height: 25px;
  background: url("../Images/copy-icon-white.svg") no-repeat center;
  background-size: 70%;
  border-radius: 5px;
}

.modal-window__copy-button:hover {
  background: var(--grey-color) url("../Images/copy-icon-black.svg") no-repeat center;
  background-size: 70%;

}

.modal-window__copy-note {
  position: absolute;
  box-sizing: border-box;
  opacity: 0;
  top: -5px;
  right: -80px;
  height: 0;
  padding: 0.5rem 0.5rem;
  font-size: 9px;
  border-radius: 12px;
  text-align: center;
  background-color: #59a32f;
  transition: opacity 0.1s ease;
}

.modal-window__copy-note.copied {
  box-sizing: border-box;
  height: 30px;
  opacity: 1;
  z-index: 2;
}