@use "../../../shared/styles/mixins.scss" as m;

.status-icon {
  @include m.box(2rem);

  &_black {
    &__planned {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/planned-icon.svg",
        $size: 100%
      );
    }

    &__in_progress {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/in-progress-icon.svg",
        $size: 100%
      );
    }

    &__on_reviewing {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/under-review-icon.svg",
        $size: 100%
      );
    }

    &__dorabotka {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/post-improvment-icon.svg",
        $size: 100%
      );
    }

    &__done {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/done-icon.svg",
        $size: 100%
      );
    }
  }

  &_white {
    &__planned {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/planned-white-icon.svg",
        $size: 100%
      );
    }

    &__in_progress {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/in-progress-white-icon.svg",
        $size: 100%
      );
    }

    &__on_reviewing {
      @include m.background-image(
        $url:
          "../../../shared/assets/tasks-statuses/under-review-white-icon.svg",
        $size: 100%
      );
    }

    &__dorabotka {
      @include m.background-image(
        $url:
          "../../../shared/assets/tasks-statuses/post-improvment-white-icon.svg",
        $size: 100%
      );
    }

    &__done {
      @include m.background-image(
        $url: "../../../shared/assets/tasks-statuses/done-white-icon.svg",
        $size: 100%
      );
    }
  }
}
